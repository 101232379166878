body {
  margin: 0;
  font-size: 0.85rem;
  background: transparent; 
  color: rgba(63,73,84,1);
  font-family: SimSun, Arial, "Arial Narrow", HELVETICA;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

audio:not([controls]) {
  display: none;
  height: 0; }

template {
  display: none; }

a {
  background-color: transparent;
  color: #006dcc;
  text-decoration: none;
  transition: color 0.1s linear; }

a,
button {
  cursor: pointer; }

a:hover,
a:focus {
  text-decoration: underline;
  color: #005fb2; }

a:active {
  color: #005fb2; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted;
  text-decoration: none;
  cursor: help; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background-color: #fff03f;
  color: #3e3e3c; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0;
  height: auto;
  max-width: 100%; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  padding: 0;
  display: block;
  margin: 2rem 0;
  border-top: 1px solid #dddbda;
  height: 1px;
  clear: both; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

input {
  line-height: normal; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

  *, *::before, *::after {
    box-sizing: border-box;
}

@media (max-width: 600px) {
  .ant-picker-panels {
    flex-direction: column;
  }
}

